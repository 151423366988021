body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea,
select,
button,
input.text,
input[type="text"],
input[type="password"],
input[type="button"],
input[type="submit"],
.input-checkbox {
-webkit-appearance: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-light-gray {
  color: #C4C4C4 !important;
}

.text-center {
  text-align: center !important;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-white {
  color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bold {
  font-weight: bold !important;
}

.w-full {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.inline-block {
  display: inline-block !important;
}

.mt-4 {
  margin-top: 16px !important;
}

.mt-8 {
  margin-top: 32px !important;
}

.mt-12 {
  margin-top: 48px !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 12px !important;
}

.-mt-4 {
  margin-top: -16px !important;
}

.mr-2 {
  margin-right: 8px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.ml-4 {
  margin-left: 16px !important;
}

.text-gray {
  color: #6C6767 !important;
}

.text-purple {
 color: #662E50 !important
}

.bg-purple {
  background-color: #662E50 !important
}

.absolute {
  position: absolute !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.float-right {
  float: right !important;
}

.text-right {
  text-align: right !important;
}

.flex {
  display: flex !important;
}

.space-between {
  align-content: space-between !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.text-decoration-none {
  text-decoration: none !important;
}
